import { mapDTO } from '@twistezo/ts-dto-mapper'

import { WorkspaceProductDTO } from 'features/product/api/types/workspaceProductDTO'
import { WorkspaceProduct } from 'features/product/types/workspaceProduct'
import { workspaceFromDTO } from 'features/workspace'
import { dateOrUndefined } from 'providers/graphql/utils'

export const workspaceProductFromDTO = (from: WorkspaceProductDTO): WorkspaceProduct =>
  mapDTO<WorkspaceProductDTO, WorkspaceProduct>({ from }).transform(workspaceProductDTO => {
    const { createdAt, from, performanceReportUrl, to, updatedAt, workspace } = workspaceProductDTO

    return {
      ...workspaceProductDTO,
      createdAt: new Date(createdAt),
      from: dateOrUndefined(from),
      performanceReportUrl: performanceReportUrl || undefined,
      to: dateOrUndefined(to),
      updatedAt: new Date(updatedAt),
      workspace: workspaceFromDTO(workspace),
    }
  })
