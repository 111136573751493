import { Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, NumberField } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import {
  RelatedTimeoutSettings,
  RelatedWorkspaceFailsafeTimeout,
} from 'components/RelatedTimeoutSettings'
import { useAuthUser } from 'features/user'
import { KNOWLEDGE_BASE_URL } from './constants'
import { Schema } from './schema'

export const Form = (): JSX.Element => {
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const {
    user: { currentWorkspace },
  } = useAuthUser()
  const { control } = useFormContext<Schema>()

  return (
    <Paper>
      <BoxForm>
        <SectionHeader
          subtitle={t('prebid.defaults.timeouts.subtitle')}
          title={t('prebid.defaults.timeouts.title')}
        />

        <Stack
          alignItems='flex-start'
          direction='row'
          spacing={2}
        >
          <NumberField
            control={control}
            label={t('prebid.defaults.timeouts.prebidTimeout.label')}
            name='prebidTimeout'
            tooltip={{
              content: t('prebid.defaults.timeouts.prebidTimeout.tooltip'),
              link: {
                title: t('common:learnMore'),
                url: KNOWLEDGE_BASE_URL,
              },
            }}
          />

          {currentWorkspace && (
            <RelatedTimeoutSettings
              items={[
                <RelatedWorkspaceFailsafeTimeout
                  key='relatedFailsafeTimeout'
                  workspace={currentWorkspace}
                />,
              ]}
            />
          )}
        </Stack>
      </BoxForm>
    </Paper>
  )
}
