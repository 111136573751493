import {
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  ToggleButton,
  ToggleButtonGroup as ToggleButtonGroupOrigin,
  Tooltip as TooltipRoot,
} from '@mui/material'
import { Controller, FieldError, FieldPath, FieldValues } from 'react-hook-form'

import Tooltip from 'components/Tooltip'
import { ToggleButtonGroupProps } from './types'

export const ToggleButtonGroup = <T extends FieldValues>({
  control,
  hint,
  label,
  name,
  options,
  tooltip: tooltipProps,
  ...toggleButtonGroupPropsOrigin
}: ToggleButtonGroupProps<T>): JSX.Element => {
  const Label = ({ error }: { error?: FieldError }): JSX.Element => (
    <Stack direction='row'>
      <FormLabel
        error={Boolean(error)}
        id={name}
      >
        {label}
      </FormLabel>

      {tooltipProps && <Tooltip {...tooltipProps} />}
    </Stack>
  )

  return (
    <Controller
      control={control}
      name={name as FieldPath<T>}
      render={({ field, field: { onChange, value }, fieldState: { error } }) => (
        <Stack
          spacing={1}
          sx={{ width: 'fit-content' }}
        >
          <Label error={error} />

          <FormControl error={Boolean(error)}>
            <>
              <TooltipRoot
                arrow
                title={hint}
              >
                <ToggleButtonGroupOrigin
                  {...field}
                  {...toggleButtonGroupPropsOrigin}
                  exclusive
                  id={name}
                  onChange={(_, newValue: string): void => onChange(newValue)}
                  value={value}
                >
                  {options.map(
                    (option: string): JSX.Element => (
                      <ToggleButton
                        key={option}
                        value={option}
                      >
                        {option.toUpperCase()}
                      </ToggleButton>
                    ),
                  )}
                </ToggleButtonGroupOrigin>
              </TooltipRoot>
            </>

            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        </Stack>
      )}
    />
  )
}
