import { CellContext, ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { ProductsHeaderMenu } from 'components/Table'
import { isSwitchDisabledBasedOnUnfilledRecovery } from 'features/inventory/ad-unit/utils/unfilledRecovery'
import { AdUnit } from 'features/inventory/types/adUnit'
import { Product } from 'features/product'
import { useAbility } from 'providers/casl'
import { ProductSwitchCell } from '../../components/ProductSwitchCell'
import { AdUnitForTable } from '../../types'
import { useSwitchAdUnitsProduct } from '../useSwitchAdUnitsProduct'
import { UseProductSwitchColumns } from './types'

export const useProductSwitchColumns = ({
  tableFilters,
  tableItemsCount,
}: UseProductSwitchColumns): ColumnDef<AdUnitForTable>[] => {
  const ability = useAbility()
  const { t: tAdUnit } = useTranslation('features/adUnit')
  const { t: tProduct } = useTranslation('features/product')

  const { update } = useSwitchAdUnitsProduct()

  const productsSwitches: Product[] = [
    'prebidStack',
    'refresher',
    'viewabilityTools',
    'priceGenius',
    'unfilledRecovery',
  ]

  const column = (product: Product): ColumnDef<AdUnitForTable> => {
    const isNotAuthorized: boolean =
      !ability.can('access', `ProductToggle-${product}`) ||
      !ability.can('update', `ProductToggle-${product}`)

    const handleToggleProductState = (enabled: boolean): void =>
      void update({ enabledValue: enabled, productType: product, terms: tableFilters })

    const Cell = (props: CellContext<AdUnitForTable, unknown>): JSX.Element => {
      const unfilledRecoveryAdUnit: AdUnit['unfilledRecoveryAdUnit'] =
        props.row.original.unfilledRecoveryAdUnit

      return (
        <ProductSwitchCell
          {...props}
          disabled={
            isNotAuthorized ||
            isSwitchDisabledBasedOnUnfilledRecovery(unfilledRecoveryAdUnit, product)
          }
          product={product}
        />
      )
    }

    return {
      accessorKey: `${product}Enabled`,
      cell: props => <Cell {...props} />,
      header: tProduct(`product.${product}`),
      meta: {
        disabledHeader: isNotAuthorized,
        headerMenu: (
          <ProductsHeaderMenu
            i18nResource={tAdUnit}
            productState={{
              disabledToggle: isNotAuthorized || tableItemsCount === 0,
              onToggle: handleToggleProductState,
            }}
          />
        ),
        nonClickable: true,
      },
    }
  }

  return productsSwitches.map((product: Product): ColumnDef<AdUnitForTable> => column(product))
}
