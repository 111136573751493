import { useMutation } from '@apollo/client'
import { omit } from 'lodash'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { useAbility } from 'providers/casl'
import { commonSchemaParametersToDTO } from '../../components/ParamsFormBox/mappers/commonSchemaParametersToDTO'
import { EditSchema } from '../../schemas/editSchema'
import { errorsWithParamsMapper } from '../utils'
import { UPDATE_WORKSPACE_PREBID_MODULE } from './api/mutation'
import { Data, Variables } from './api/types'
import { Props, UseUpdateWorkspacePrebidModule } from './types'

export const useUpdateWorkspacePrebidModule = ({
  moduleType,
}: Props): UseUpdateWorkspacePrebidModule => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: `prebid.modules.${moduleType}.edit`,
  })
  const ability = useAbility()
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading } = useContext(PrebidModulesContext)
  const [errors, setErrors] = useState<FieldErrors<EditSchema>>({})

  const [mutate] = useMutation(UPDATE_WORKSPACE_PREBID_MODULE, {
    onCompleted: ({
      updateWorkspacePrebidModule: { errors, workspacePrebidModule },
    }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(errorsWithParamsMapper<EditSchema>(errors))
        enqueueSnackbar(t('error'), { variant: 'error' })
      } else if (workspacePrebidModule) {
        setErrors({})
        enqueueSnackbar(t('success'), { variant: 'success' })
      }
    },
    onError: (): void => setLoading(false),
  })

  const update: UseUpdateWorkspacePrebidModule['update'] = (id, data) => {
    setLoading(true)

    const { enabled, ...params } = data
    const inputParams = commonSchemaParametersToDTO(params)
    const input: Variables = {
      enabled,
      id,
    }

    if (ability.can('update', `GlobalSetupField-prebid-modules-params-${params.accountType}`)) {
      input.params = inputParams
    }

    return mutate({
      variables: {
        input: enabled === undefined ? omit(input, ['enabled']) : input,
      },
    })
  }

  return { errors, update }
}
