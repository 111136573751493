import { Ability } from 'providers/casl'
import { PrebidModule, PrebidModuleType } from './types/prebidModule'

type CanUpdateProps = {
  ability: Ability
  account: PrebidModule['account']
  moduleType: PrebidModuleType
}

export const canUpdate = ({ ability, account, moduleType }: CanUpdateProps): boolean =>
  account === 'own' && ability.can('update', `GlobalSetupFeature-${moduleType}`)
