import { z } from 'zod'

import { commonSchema } from './commonSchema'
import { MediaTypesSchema } from './mediaTypesSchema'

export const addSchema = commonSchema

export type AddSchema = z.infer<typeof commonSchema>

export const mediaTypesDefault: MediaTypesSchema = {
  defaultAttribute: {
    bannerEnabled: false,
    bannerSizes: [],
    nativeEnabled: false,
    outstreamEnabled: false,
    playerSize: [],
  },
  kind: 'default',
  nativeConfiguration: '{}',
  outstreamConfiguration: '{}',
  viewportAttributes: {},
}

export const addSchemaDefault: AddSchema = {
  divId: undefined,
  domainId: '',
  mediaTypes: mediaTypesDefault,
  name: undefined,
  outOfPage: false,
  outOfPageType: 'standard',
  pageIds: [],
  path: '',
}
