import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { GeneralPageContext } from 'features/globalSetup/contexts/GeneralPageContext'
import { updateUser, useAuthUser } from 'features/user'
import { useAbility } from 'providers/casl'
import { mapGraphQLErrors } from 'providers/graphql'
import { Schema } from '../../schema'
import { fromDTO } from './api/mapper'
import { UPDATE_WORKSPACE_FOR_GLOBAL_SETUP_GENERAL } from './api/mutation'
import { Data, WorkspaceSliceDTO } from './api/types'
import { UseUpdateWorkspace } from './types'

export const useUpdateWorkspace = (): UseUpdateWorkspace => {
  const ability = useAbility()
  const { t } = useTranslation('features/globalSetup')
  const {
    user: { currentWorkspace },
  } = useAuthUser()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { setLoading, setWorkspace } = useContext(GeneralPageContext)
  const [errors, setErrors] = useState<FieldErrors<Schema>>({})

  const updateUserStore = (workspace: WorkspaceSliceDTO): void => {
    if (!currentWorkspace) {
      throw Error('User workspace should exists')
    }

    void dispatch(
      updateUser({
        currentWorkspace: {
          ...currentWorkspace,
          ...fromDTO(workspace),
        },
      }),
    )
  }

  const [mutate] = useMutation(UPDATE_WORKSPACE_FOR_GLOBAL_SETUP_GENERAL(ability), {
    onCompleted: ({ updateWorkspace: { errors, workspace } }: Data): void => {
      setLoading(false)

      if (errors && errors.length > 0) {
        setErrors(mapGraphQLErrors(errors))
        enqueueSnackbar(t('general.workspace.update.error'), { variant: 'error' })
      } else if (workspace) {
        setErrors({})
        enqueueSnackbar(t('general.workspace.update.success'), { variant: 'success' })

        setWorkspace(fromDTO(workspace))
        updateUserStore(workspace)
      }
    },
    onError: (): void => setLoading(false),
  })

  const update = (data: Schema) => {
    setLoading(true)

    return mutate({ variables: { input: data } })
  }

  return { errors, update }
}
