import { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { PrebidModuleParameter } from 'features/globalSetup/types/prebidModule'
import { usePrevious } from 'hooks/usePrevious'
import { useGetPrebidModuleParametersSchema } from '../hooks/useGetPrebidModuleParametersSchema'
import { CommonSchema } from '../schemas/commonSchema'
import { unregisterParameters } from '../utils'
import { MainSection } from './sections/MainSection'
import { ParametersSection } from './sections/ParametersSection'
import { CommonFormProps } from './types'

export const AnalyticsAdaptersForm = ({ variant }: CommonFormProps): JSX.Element => {
  const { t } = useTranslation(['features/globalSetup', 'common'])
  const { setSaveButtonText } = useContext(PrebidModulesContext)
  const { unregister, watch } = useFormContext<CommonSchema>()
  const { accountType, prebidModuleId } = watch()

  useEffect(() => {
    if (setSaveButtonText !== undefined) {
      setSaveButtonText(
        accountType === 'own'
          ? t('common:form.action.save')
          : t('prebid.modules.analytics.add.request'),
      )
    }
  }, [accountType]) // eslint-disable-line react-hooks/exhaustive-deps

  const { parametersSchema } = useGetPrebidModuleParametersSchema({ id: prebidModuleId })
  const previousParametersSchema = usePrevious<PrebidModuleParameter[]>(parametersSchema)

  useEffect(() => {
    if (previousParametersSchema && previousParametersSchema.length > 0) {
      unregisterParameters<CommonSchema>(previousParametersSchema, unregister)
    }
  }, [parametersSchema]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {variant === 'add' && <MainSection />}

      {accountType === 'own' && parametersSchema.length > 0 && (
        <ParametersSection parametersSchema={parametersSchema} />
      )}
    </>
  )
}
