import { Stack, SxProps, TextField, Theme } from '@mui/material'
import { ChangeEvent } from 'react'
import { Controller, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form'

import Tooltip from 'components/Tooltip'
import { inputWidth } from 'providers/material-ui/theme/constants'
import { NumberFieldProps } from './types'

export const NumberField = <T extends FieldValues>({
  control,
  name,
  tooltip: tooltipProps,
  type = 'integer',
  useTooltipForErrors,
  ...textFieldProps
}: NumberFieldProps<T>): JSX.Element => {
  const handleInputOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: ControllerRenderProps<T, FieldPath<T>>,
  ) => void field.onChange(Number(event.target.value) || 0)

  const calculatedWidth: number = inputWidth[textFieldProps.width ?? 'sm']

  const containerSxProps: SxProps<Theme> = {
    minWidth: calculatedWidth,
  }

  const inputSxProps: SxProps<Theme> = {
    minWidth: calculatedWidth,
    width: calculatedWidth,
  }

  return (
    <Controller
      control={control}
      name={name as FieldPath<T>}
      render={({ field, field: { value }, fieldState: { error } }) => {
        const hasError: boolean = Boolean(error)
        const errorMessage: string = error?.message || ''

        return (
          <Stack
            direction='row'
            sx={containerSxProps}
          >
            <TextField
              {...field}
              {...textFieldProps}
              error={hasError}
              helperText={!useTooltipForErrors && errorMessage}
              id={name}
              inputProps={{ step: type === 'integer' ? 1 : 0.01 }}
              onChange={event => handleInputOnChange(event, field)}
              sx={inputSxProps}
              type='number'
              value={value.toString()}
            />

            {tooltipProps && <Tooltip {...tooltipProps} />}

            {hasError && useTooltipForErrors && (
              <Tooltip
                content={errorMessage}
                variant='error'
              />
            )}
          </Stack>
        )
      }}
    />
  )
}
