import { MediaTypeDefaultAttribute } from 'features/inventory/ad-unit/types/mediaTypes'
import { mediaTypesDefault } from '../../../../schemas/addSchema'

export const composeTemporaryMediaType = (): MediaTypeDefaultAttribute => {
  const { bannerSizes, playerSize, ...defaultMediaTypes } = mediaTypesDefault.defaultAttribute

  return {
    bannerSizes: bannerSizes,
    id: new Date().toISOString(),
    playerSize: playerSize,
    updatedAt: new Date(),
    ...defaultMediaTypes,
  }
}
