import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { EditSchema } from 'features/user/forms/UserEmployeeForm/schemas'

export const ModuleEnabledSection = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.userId.edit.general',
  })
  const { control } = useFormContext<EditSchema>()

  return (
    <Paper>
      <SectionHeader
        subtitle={t('enabled.header.subtitle')}
        title={t('enabled.header.title')}
        titleEndAdornment={
          <Switch
            control={control}
            name='enabled'
          />
        }
      />
    </Paper>
  )
}
