import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Box,
  ListItem,
  ListItemButtonProps as ListItemButtonPropsRoot,
  ListItemButton as ListItemButtonRoot,
  ListItemIcon,
  ListItemProps,
  Tooltip,
  Typography,
} from '@mui/material'
import { ReactNode } from 'react'
import { Link, Location, useLocation } from 'react-router-dom'

import { ListItemButtonProps } from './types'

export const ListItemButton = ({
  collapsedX,
  collapsedY,
  disabled,
  endAdornment,
  icon,
  newTab,
  onClick,
  onCollapseY,
  selectable,
  text,
  to,
  withTooltip,
  ...props
}: ListItemButtonProps): JSX.Element => {
  const location: Location = useLocation()

  const ListItemStyled = ({ ...localProps }: ListItemProps) => (
    <ListItem
      {...props}
      {...localProps}
      onClick={(event: React.MouseEvent<HTMLLIElement>): void => {
        if (disabled) return

        onCollapseY && onCollapseY(event)
        onClick && onClick(event)
      }}
      sx={{ p: 0, ...props.sx }}
    />
  )

  const ListItemButtonStyled = ({ ...props }: ListItemButtonPropsRoot) => (
    <ListItemButtonRoot
      {...props}
      {...(to && {
        component: Link,
        rel: newTab ? 'noopener noreferrer' : undefined,
        selected: selectable && to === location.pathname,
        target: newTab ? '_blank' : undefined,
        to: to,
      })}
      disabled={disabled}
    />
  )

  const ListItemIconStyled = (): JSX.Element => (
    <ListItemIcon
      sx={{
        minWidth: 'auto',
        mr: 2,
      }}
    >
      {icon}
    </ListItemIcon>
  )

  const TypographyStyled = (): JSX.Element => {
    const Content: JSX.Element = <Typography sx={{ pl: icon ? 0 : 5 }}>{text}</Typography>

    return withTooltip ? <Tooltip title={text}>{Content}</Tooltip> : Content
  }

  const EndAndornment = (): JSX.Element => {
    const render = (): ReactNode => {
      if (endAdornment) {
        return endAdornment
      } else if (!collapsedX && onCollapseY) {
        return collapsedY ? <ExpandLess /> : <ExpandMore />
      }
    }

    return (
      <Box
        sx={{
          display: 'flex',
          ml: 'auto',
        }}
      >
        {render()}
      </Box>
    )
  }

  return (
    <ListItemStyled>
      <ListItemButtonStyled>
        {icon && <ListItemIconStyled />}
        {!collapsedX && <TypographyStyled />}
        <EndAndornment />
      </ListItemButtonStyled>
    </ListItemStyled>
  )
}
