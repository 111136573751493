import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Filter, { FilterValue } from 'components/Filter'
import Paper from 'components/Paper'
import { TableHeader } from 'components/Table'
import {
  InventoryConnectionForm,
  UserIdDomainsTable,
} from 'features/globalSetup/forms/Prebid/Modules/UserIdsForm/InventoryConnection'

export const InventoryConnectionPage = (): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.userId.edit.inventoryConnection',
  })
  const [filters, setFilters] = useState<FilterValue[]>([])
  const UserIdDomainsTableFilter = (
    <Filter
      id='userIdDomainsTableFilter'
      onChange={setFilters}
      placeholder={t('list.filter')}
      values={filters}
    />
  )

  return (
    <Paper>
      <TableHeader
        bottomSpacing
        description={t('header.subtitle')}
        filter={UserIdDomainsTableFilter}
        title={t('header.title')}
      />

      <InventoryConnectionForm>
        <UserIdDomainsTable filters={filters} />
      </InventoryConnectionForm>
    </Paper>
  )
}
