import { useContext } from 'react'

import { Form } from 'components/Form'
import { PrebidModulesContext } from 'features/globalSetup/contexts/PrebidModulesContext'
import { schema, Schema, schemaDefaults } from './schema'
import { InventoryConnectionFormProps } from './types'

export const InventoryConnectionForm = ({ children }: InventoryConnectionFormProps) => {
  const { formId, setTouched, setValid } = useContext(PrebidModulesContext)

  return (
    <Form<Schema>
      id={formId}
      onSubmit={() => {}}
      onTouched={setTouched}
      onValid={setValid}
      schema={schema}
      schemaDefaults={schemaDefaults()}
    >
      {() => children}
    </Form>
  )
}
