import _ from 'lodash'
import { z } from 'zod'

import { accounts, statuses } from 'features/globalSetup/types/workspaceBidder'
import { WorkspaceAmazonSlice } from '../../hooks/useGetWorkspaceAmazon'

const accountsEnum = z.enum(accounts)
const pubId = z.string().length(36)

export const commonFields = z.object({
  enabled: z.boolean().optional(),
  id: z.string().optional(),
})

export const ownFields = z
  .object({
    accountType: accountsEnum.extract(['own']),
    pubId,
  })
  .merge(commonFields)

export const yieldbirdFields = z
  .object({
    accountType: accountsEnum.extract(['yieldbird']),
    status: z.enum(statuses).optional(),
  })
  .merge(commonFields)

export const thirdPartyProviderFields = z
  .object({
    accountType: accountsEnum.extract(['thirdPartyProvider']),
    pubId,
    supplyChainId: z.string(),
  })
  .merge(commonFields)

export const schema = z.discriminatedUnion('accountType', [
  ownFields,
  yieldbirdFields,
  thirdPartyProviderFields,
])

export type CommonSchema = z.infer<typeof commonFields>
export type Schema = z.infer<typeof schema>

export const schemaDefault = (workspaceAmazon: null | undefined | WorkspaceAmazonSlice): Schema => {
  if (_.isNil(workspaceAmazon)) {
    return {
      accountType: 'own',
      enabled: false,
      pubId: '',
    }
  }

  const { accountType, enabled, id, pubId, status, supplyChain } = workspaceAmazon

  return {
    accountType,
    enabled,
    id,
    pubId: pubId ?? '',
    status,
    supplyChainId: supplyChain ? supplyChain.id : '',
  }
}
