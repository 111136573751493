import { Box, Stack, Typography } from '@mui/material'

import { SpaceBox } from 'components/Box'
import Space from 'components/Space'
import { CONTAINER_BOTTOM_SPACING } from 'providers/material-ui/theme/constants'
import { TableHeaderProps } from './types'

export const TableHeader = ({
  actions,
  bottomSpacing,
  description,
  descriptionEndAdornment,
  filter,
  title,
}: TableHeaderProps): JSX.Element => (
  <Box sx={{ mb: bottomSpacing ? CONTAINER_BOTTOM_SPACING : 0 }}>
    <Stack
      alignItems='center'
      direction='row'
      gap={2}
    >
      <Typography
        data-cy='table-header-title'
        variant='h5'
      >
        {title}
      </Typography>

      {actions}

      <SpaceBox />

      {filter}
    </Stack>

    {description && (
      <Typography
        data-cy='table-header-description'
        sx={{ pt: 1 }}
        variant='subtitle1'
      >
        {description}

        {descriptionEndAdornment && <Space />}
        {descriptionEndAdornment}
      </Typography>
    )}
  </Box>
)
