import { z } from 'zod'

import { WorkspacePrebidConfiguration } from 'features/globalSetup/types/workspacePrebidConfiguration'
import { Workspace } from 'features/workspace'

export const schema = z.object({
  prebidTimeout: z.number(),
  workspaceId: z.string(),
})

export type Schema = z.infer<typeof schema>

export const schemaDefault = (
  wrkspacePrebidConfiguration: WorkspacePrebidConfiguration,
  workspaceId: Workspace['id'],
): Schema => ({
  prebidTimeout: wrkspacePrebidConfiguration.prebidTimeout,
  workspaceId,
})
