import { ColumnDef } from '@tanstack/react-table'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch, TagsField } from 'components/Form'
import { LastModifiedCell } from 'components/Table'
import { MediaTypeViewportAttribute } from 'features/inventory/ad-unit/types/mediaTypes'
import { PREDEFINED_AD_UNIT_SIZES } from 'features/inventory/viewport'
import { useAbility } from 'providers/casl'
import { CommonSchema } from '../../../../schemas/commonSchema'
import { ActionsCell } from './components/ActionsCell'
import { NameCell } from './components/NameCell'
import { ViewportWidthCell } from './components/ViewportWidthCell'

export const useColumns = (): ColumnDef<MediaTypeViewportAttribute>[] => {
  const { t } = useTranslation('features/adUnit', {
    keyPrefix: 'form.general.mediaTypes.viewportList.header',
  })
  const { control } = useFormContext<CommonSchema>()
  const ability = useAbility()
  const disabled = ability.cannot('update', 'AdUnitFeature-mediaTypes')

  return [
    {
      accessorKey: 'viewport.name',
      cell: ctx => <NameCell {...ctx} />,
      header: t('name.label'),
    },
    {
      accessorKey: 'viewport.minimumWidth',
      cell: ctx => <ViewportWidthCell {...ctx} />,
      header: t('viewportWidth'),
    },
    {
      accessorKey: 'bannerEnabled',
      cell: ({
        getValue,
        row: {
          original: { id },
        },
      }) => (
        <Switch
          control={control}
          disabled={disabled}
          name={`mediaTypes.viewportAttributes.${id}.bannerEnabled`}
          value={Boolean(getValue())}
        />
      ),
      header: t('bannerEnabled'),
    },
    {
      accessorKey: 'bannerSizes',
      cell: ({
        row: {
          original: { id },
        },
      }) => (
        <TagsField
          control={control}
          disabled={disabled}
          name={`mediaTypes.viewportAttributes.${id}.bannerSizes`}
          options={PREDEFINED_AD_UNIT_SIZES}
        />
      ),
      header: t('bannerSizes'),
    },
    {
      accessorKey: 'outstreamEnabled',
      cell: ({
        getValue,
        row: {
          original: { id },
        },
      }) => (
        <Switch
          control={control}
          disabled={disabled}
          name={`mediaTypes.viewportAttributes.${id}.outstreamEnabled`}
          value={Boolean(getValue())}
        />
      ),
      header: t('outstreamEnabled'),
    },
    {
      accessorKey: 'playerSize',
      cell: ({
        row: {
          original: { id },
        },
      }) => (
        <TagsField
          control={control}
          disabled={disabled}
          name={`mediaTypes.viewportAttributes.${id}.playerSize`}
          options={PREDEFINED_AD_UNIT_SIZES}
        />
      ),
      header: t('playerSize'),
    },
    {
      accessorKey: 'nativeEnabled',
      cell: ({
        getValue,
        row: {
          original: { id },
        },
      }) => (
        <Switch
          control={control}
          disabled={disabled}
          name={`mediaTypes.viewportAttributes.${id}.nativeEnabled`}
          value={Boolean(getValue())}
        />
      ),
      header: t('nativeEnabled'),
    },
    {
      accessorKey: 'updatedAt',
      cell: ctx => <LastModifiedCell {...ctx} />,
      header: t('updatedAt'),
    },
    {
      accessorKey: 'actions',
      cell: () => <ActionsCell />,
      header: t('actions'),
    },
  ]
}
