import { mapDTO } from '@twistezo/ts-dto-mapper'

import { MediaTypeDefaultAttributeDTO } from 'features/inventory/ad-unit/api/types/mediaTypesDTO'
import { MediaTypeDefaultAttribute } from 'features/inventory/ad-unit/types/mediaTypes'
import { commonMediaTypeFieldsFromDTO } from './commonMediaTypes'

export const mediaTypeDefaultAttributesFromDTO = (
  from: MediaTypeDefaultAttributeDTO,
): MediaTypeDefaultAttribute =>
  mapDTO<MediaTypeDefaultAttributeDTO, MediaTypeDefaultAttribute>({ from }).transform(
    (dto: MediaTypeDefaultAttributeDTO): MediaTypeDefaultAttribute => {
      const { id, updatedAt } = dto

      return {
        id,
        updatedAt: new Date(updatedAt),
        ...commonMediaTypeFieldsFromDTO(dto),
      }
    },
  )
