import { Row } from '@tanstack/react-table'

import { MediaTypeViewportAttribute } from 'features/inventory/ad-unit/types/mediaTypes'

type NextViewportWidthProps = {
  currentViewportWidth: MediaTypeViewportAttribute['viewport']['minimumWidth']
  index: number
  rows: Row<MediaTypeViewportAttribute>[]
}

export const nextViewportWidth = ({
  currentViewportWidth,
  index,
  rows,
}: NextViewportWidthProps): number => {
  let nextIndex = index + 1

  while (
    nextIndex < rows.length &&
    currentViewportWidth === rows[nextIndex].original.viewport.minimumWidth
  ) {
    nextIndex++
  }

  return rows[nextIndex].original.viewport.minimumWidth - 1
}
