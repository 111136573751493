import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { PrebidModuleParameter } from 'features/globalSetup/types/prebidModule'
import { usePrevious } from 'hooks/usePrevious'
import { useAbility } from 'providers/casl'
import { useGetPrebidModuleParametersSchema } from '../../hooks/useGetPrebidModuleParametersSchema'
import { CommonSchema } from '../../schemas/commonSchema'
import { unregisterParameters } from '../../utils'
import { ModuleEnabledSection } from './sections/ModuleEnabledSection'
import { ParametersSection } from './sections/ParametersSection'
import { UserIdSection } from './sections/UserIdSection'
import { CommonFormProps } from './types'

export const UserIdsForm = ({ variant }: CommonFormProps): JSX.Element => {
  const { unregister, watch } = useFormContext<CommonSchema>()
  const { accountType, prebidModuleId } = watch()
  const { parametersSchema } = useGetPrebidModuleParametersSchema({ id: prebidModuleId })
  const previousParametersSchema = usePrevious<PrebidModuleParameter[]>(parametersSchema)

  const ability = useAbility()
  const hasVisibleParametersSection =
    parametersSchema.length > 0 &&
    ability.can('update', `GlobalSetupField-prebid-modules-params-${accountType}`)

  useEffect(() => {
    if (previousParametersSchema && previousParametersSchema.length > 0) {
      unregisterParameters<CommonSchema>(previousParametersSchema, unregister)
    }
  }, [parametersSchema]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {variant === 'edit' && <ModuleEnabledSection />}

      {<UserIdSection variant={variant} />}

      {hasVisibleParametersSection && <ParametersSection {...{ accountType, parametersSchema }} />}
    </>
  )
}
