import { CellContext, Row } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { MediaTypeViewportAttribute } from 'features/inventory/ad-unit/types/mediaTypes'
import { nextViewportWidth } from './utils'

export const ViewportWidthCell = ({
  row: {
    index,
    original: {
      viewport: { minimumWidth: currentViewportWidth },
    },
  },
  table: { getRowModel },
}: CellContext<MediaTypeViewportAttribute, unknown>): string => {
  const { t } = useTranslation('features/adUnit', {
    keyPrefix: 'form.general.mediaTypes.viewportList.cells.viewportWidth',
  })

  const rows: Row<MediaTypeViewportAttribute>[] = getRowModel().rows

  if (index < rows.length - 1) {
    return t('range', {
      currentViewportWidth,
      nextViewportWidth: nextViewportWidth({ currentViewportWidth, index, rows }),
    })
  } else if (index === rows.length - 1) {
    return t('last', { currentViewportWidth })
  } else {
    throw new Error('Could not match Media Type range')
  }
}
