import _ from 'lodash'

import { MediaTypes, MediaTypeViewportAttribute } from 'features/inventory/ad-unit/types/mediaTypes'
import { MediaTypeSchema, MediaTypesSchema, ViewportAttributesSchema } from './mediaTypesSchema'

const PICKABE_FIELDS: (keyof MediaTypeSchema)[] = [
  'bannerEnabled',
  'bannerSizes',
  'nativeEnabled',
  'outstreamEnabled',
  'playerSize',
]

export const mediaTypesToSchema = ({
  defaultAttribute,
  kind,
  nativeConfiguration,
  outstreamConfiguration,
  viewportAttributes,
}: MediaTypes): MediaTypesSchema => ({
  defaultAttribute: _.pick(defaultAttribute, PICKABE_FIELDS),
  kind,
  nativeConfiguration,
  outstreamConfiguration,
  viewportAttributes: viewportAttributesToSchema(viewportAttributes),
})

export const viewportAttributesToSchema = (
  viewportAttributes: MediaTypeViewportAttribute[],
): ViewportAttributesSchema =>
  _.chain(viewportAttributes)
    .keyBy('id')
    .mapValues(
      (viewportAttribute: MediaTypeViewportAttribute): MediaTypeSchema =>
        _.pick(viewportAttribute, PICKABE_FIELDS),
    )
    .value()
