import { useTranslation } from 'react-i18next'

import { SectionHeader } from 'components/Header'
import { ParamsFormBox } from '../../../../components/ParamsFormBox'
import { ParameterSectionWrapper } from './ParametersSectionWrapper'
import { ParametersSectionProps } from './types'

export const ParametersSection = ({
  accountType,
  parametersSchema,
}: ParametersSectionProps): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.userId.add.parameters',
  })

  return (
    <ParameterSectionWrapper accountType={accountType}>
      <SectionHeader
        bottomSpacing
        subtitle={t('header.subtitle')}
        title={t('header.title')}
      />

      <ParamsFormBox parametersSchema={parametersSchema} />
    </ParameterSectionWrapper>
  )
}
