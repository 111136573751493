import { useContext } from 'react'

import { Table } from 'components/Table'
import { AddEditAdUnitContext } from 'features/inventory/ad-unit/contexts/AddEditAdUnitContext'
import { MediaTypeViewportAttribute } from 'features/inventory/ad-unit/types/mediaTypes'
import { CommonFormProps } from '../../../../types'
import { useColumns } from './useColumns'

export const ViewportsTable = ({ variant }: CommonFormProps): JSX.Element => {
  const { adUnit } = useContext(AddEditAdUnitContext)
  const columns = useColumns()

  let data: MediaTypeViewportAttribute[] = []

  if (adUnit && variant === 'edit') {
    data = adUnit.mediaTypes.viewportAttributes
  }

  return (
    <Table
      columns={columns}
      data={data}
    />
  )
}
