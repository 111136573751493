import { RouteObject } from 'react-router-dom'

import { PerformancePage } from 'features/performance/pages/PerformancePage'
import AuthorizedRoute from 'providers/router/components/AuthorizedRoute'
import { ROUTES } from './routePaths'

export const performanceRoutes: RouteObject[] = [
  {
    element: (
      <AuthorizedRoute
        access='PerformancePage'
        page={<PerformancePage />}
      />
    ),
    path: ROUTES.PERFORMANCE.PRODUCT,
  },
]
