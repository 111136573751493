import { Divider } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { BoxForm, CodeField, RadioGroup } from 'components/Form'
import { SectionHeader } from 'components/Header'
import { TextLink } from 'components/Link'
import Paper from 'components/Paper'
import { useAbility } from 'providers/casl'
import { CommonSchema } from '../../schemas/commonSchema'
import { CommonFormProps } from '../../types'
import { DefaultTable } from './components/DefaultTable'
import { ViewportsTable } from './components/ViewportsTable'
import { KNOWLEDGE_BASE_URL } from './constants'
import { useMediaTypesKindOptions } from './hooks/useMediaTypesKindOptions'

export const MediaTypesSection = ({ variant }: CommonFormProps): JSX.Element => {
  const ability = useAbility()
  const { t } = useTranslation(['features/adUnit', 'common'])
  const { control, watch } = useFormContext<CommonSchema>()

  const options = useMediaTypesKindOptions()
  const {
    mediaTypes: { kind },
  } = watch()

  const disabledCodeFields = ability.cannot('update', 'AdUnitFeature-mediaTypes')

  return (
    <Paper>
      <SectionHeader
        bottomSpacing
        subtitle={t('form.general.mediaTypes.subtitle')}
        subtitleEndAdornment={
          <TextLink
            link={{
              title: t('common:learnMore'),
              url: KNOWLEDGE_BASE_URL.MEDIA_TYPES,
            }}
          />
        }
        title={t('form.general.mediaTypes.title')}
      />

      <RadioGroup
        bottomSpacing
        control={control}
        label={t('form.general.mediaTypes.kind.label')}
        name='mediaTypes.kind'
        options={options}
        row
      />

      {kind === 'default' ? (
        <DefaultTable variant={variant} />
      ) : (
        <ViewportsTable variant={variant} />
      )}

      <Divider sx={{ my: 3 }} />

      <SectionHeader
        bottomSpacing
        subtitle={t('form.general.mediaTypes.configuration.subtitle')}
        title={t('form.general.mediaTypes.configuration.title')}
      />

      <BoxForm>
        <CodeField
          control={control}
          disabled={disabledCodeFields}
          language='json'
          name='mediaTypes.outstreamConfiguration'
          title={t('form.general.mediaTypes.configuration.outstreamConfiguration.header')}
          tooltip={{
            content: t(
              'form.general.mediaTypes.configuration.outstreamConfiguration.tooltip.content',
            ),
            link: {
              title: t(
                'form.general.mediaTypes.configuration.outstreamConfiguration.tooltip.title',
              ),
              url: KNOWLEDGE_BASE_URL.OUTSTREAM_CONFIGURATION,
            },
          }}
        />

        <CodeField
          control={control}
          disabled={disabledCodeFields}
          language='json'
          name='mediaTypes.nativeConfiguration'
          title={t('form.general.mediaTypes.configuration.nativeConfiguration.header')}
          tooltip={{
            content: t('form.general.mediaTypes.configuration.nativeConfiguration.tooltip.content'),
            link: {
              title: t('form.general.mediaTypes.configuration.nativeConfiguration.tooltip.title'),
              url: KNOWLEDGE_BASE_URL.NATIVE_CONFIGURATION,
            },
          }}
        />
      </BoxForm>
    </Paper>
  )
}
