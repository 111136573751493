import { z } from 'zod'

import { mediaTypesKindValues } from 'features/inventory/ad-unit/types/mediaTypes'
import { validateSizes } from 'features/inventory/viewport'
import { validateJSONSchema } from 'utils/json'

const mediaTypeSchema = z.object({
  bannerEnabled: z.boolean(),
  bannerSizes: z.string().array().superRefine(validateSizes),
  nativeEnabled: z.boolean(),
  outstreamEnabled: z.boolean(),
  playerSize: z.string().array().superRefine(validateSizes),
})

const viewportAttributesSchema = z.record(z.string(), mediaTypeSchema).optional()

export const mediaTypesSchema = z.object({
  defaultAttribute: mediaTypeSchema,
  kind: z.enum(mediaTypesKindValues),
  nativeConfiguration: z.string().superRefine(validateJSONSchema),
  outstreamConfiguration: z.string().superRefine(validateJSONSchema),
  viewportAttributes: viewportAttributesSchema,
})

export type MediaTypeSchema = z.infer<typeof mediaTypeSchema>
export type MediaTypesSchema = z.infer<typeof mediaTypesSchema>
export type ViewportAttributesSchema = z.infer<typeof viewportAttributesSchema>
