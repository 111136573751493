import { mapDTO } from '@twistezo/ts-dto-mapper'

import { MediaTypeViewportAttributeDTO } from 'features/inventory/ad-unit/api/types/mediaTypesDTO'
import { MediaTypeViewportAttribute } from 'features/inventory/ad-unit/types/mediaTypes'
import { commonMediaTypeFieldsFromDTO } from './commonMediaTypes'

export const mediaTypeViewportAttributesFromDTO = (
  from: MediaTypeViewportAttributeDTO,
): MediaTypeViewportAttribute =>
  mapDTO<MediaTypeViewportAttributeDTO, MediaTypeViewportAttribute>({ from }).transform(
    (dto: MediaTypeViewportAttributeDTO): MediaTypeViewportAttribute => {
      const { id, updatedAt, viewport } = dto
      const { minimumWidth, name } = viewport

      return {
        id,
        updatedAt: new Date(updatedAt),
        viewport: {
          minimumWidth,
          name,
          ...commonMediaTypeFieldsFromDTO(viewport),
        },
        ...commonMediaTypeFieldsFromDTO(dto),
      }
    },
  )
