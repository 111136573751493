import { useTranslation } from 'react-i18next'

import { SectionHeader } from 'components/Header'
import Paper from 'components/Paper'
import { PrebidModuleParameter } from 'features/globalSetup/types/prebidModule'
import { ParamsFormBox } from '../../components/ParamsFormBox'

type Props = {
  parametersSchema: PrebidModuleParameter[]
}

export const ParametersSection = ({ parametersSchema }: Props): JSX.Element => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.analytics.add.analytics.parameters',
  })

  return (
    <Paper>
      <SectionHeader
        bottomSpacing
        subtitle={t('header.subtitle')}
        title={t('header.title')}
      />

      <ParamsFormBox parametersSchema={parametersSchema} />
    </Paper>
  )
}
