import { Row } from '@tanstack/react-table'

import { Switch } from 'components/Switch'
import { Product } from 'features/product'
import { AdUnitForTable } from '../../types'
import { useSwitchAdUnitProduct } from './hooks/useSwitchAdUnitProduct'
import { ProductSwitchCellProps } from './types'
import { useSnackbar } from './useSnackbar'

export const ProductSwitchCell = ({
  column: { id: columnId },
  disabled,
  getValue,
  product,
  row: {
    index,
    original: { id, path },
  },
  table,
}: ProductSwitchCellProps) => {
  const { update } = useSwitchAdUnitProduct()
  const { snackbar } = useSnackbar({ path, product })

  const tryUpdateRelatedAdUnits = (value: boolean): void => {
    const products: Product[] = ['viewabilityTools', 'priceGenius']
    const related: Row<AdUnitForTable>[] = table
      .getCoreRowModel()
      .rows.filter(
        ({ original }: Row<AdUnitForTable>): boolean =>
          products.includes(product) && original.path === path && original.id !== id,
      )

    table.options.meta?.updateData?.(
      related.map((row: Row<AdUnitForTable>) => ({ columnId, rowIndex: row.index, value })),
    )
  }

  const updateToggle = (value: boolean): void => {
    const data = [{ columnId, rowIndex: index, value }]

    table.options.meta?.updateData?.(data)
    tryUpdateRelatedAdUnits(value)
  }

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const value: boolean = event.target.checked
    const previousValue: boolean = !value

    updateToggle(value)

    const { data } = await update({ id, product, value })
    const { errors } = { ...data?.updateAdUnitProductEnabled }

    if (errors && errors.length > 0) {
      updateToggle(previousValue)
      snackbar(value, 'error')
    } else {
      snackbar(value)
    }
  }

  return (
    <Switch
      checked={Boolean(getValue())}
      disabled={disabled}
      name={columnId}
      onChange={handleChange}
    />
  )
}
