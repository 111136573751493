import { ColumnDef } from '@tanstack/react-table'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Form'
import { LastModifiedCell } from 'components/Table'
import { DomainPrebidModuleSlice } from '../../hooks/useGetPrebidModuleDomains/types'
import { Schema } from '../../schema'

export const useColumns = (): ColumnDef<DomainPrebidModuleSlice>[] => {
  const { t } = useTranslation('features/globalSetup', {
    keyPrefix: 'prebid.modules.userId.edit.inventoryConnection.list.header',
  })

  const { control } = useFormContext<Schema>()

  return [
    {
      accessorKey: 'domain.name',
      header: t('domainName'),
    },
    {
      accessorKey: 'enabled',
      cell: ({
        cell: { getValue },
        row: {
          original: { id },
        },
      }) => (
        <Switch<Schema>
          control={control}
          name={`domainPrebidModuleAttributes.${id}`}
          value={getValue()}
        />
      ),
      header: t('enabled'),
      meta: { nonClickable: true },
    },
    {
      accessorKey: 'updatedAt',
      cell: props => <LastModifiedCell {...props} />,
      header: t('updatedAt'),
    },
  ]
}
